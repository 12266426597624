import FooterComponent from '../components/footer';
import logo from '../data/main-logo.png';

export default function HomePage(): JSX.Element {
  return (
    <div>
      <div className='home'>
        <div className='home-text'>
          Wasped & Screwed: The Label Nobody Asked For
        </div>
        <img
          className='label-logo'
          src={logo}
        />
      </div>
      <FooterComponent />
    </div>
  );
}