import FooterComponent from '../components/footer';

export default function ContactPage(): JSX.Element {
  return (
    <div className='contact-page'>
      <div className='contact-info'>
        Here are some different ways to get in touch with us:
        <p className='address'>
          110 Founders Ave.<br></br>
          Apt. 1408<br></br>
          Falls Church, VA 22046
        </p>
        <p className='email'>
          tmeyer2115@gmail.com
        </p>
        Contact us to get copies of our releases on cassette or vinyl.
      </div>
      <FooterComponent />
    </div>
  );
}