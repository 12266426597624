import Release from '../models/release';
import Track from '../models/track';

//@ts-ignore
import { MediaPlayer } from '@cassette/player';

import '@cassette/player/dist/css/cassette-player.css';

interface Props {
  release: Release
}

export default function ReleaseComponent(props: Props): JSX.Element {
  return (
    <div className='release'>
      <div className='release-title'>
        {props.release.name}
      </div>
      {renderReleaseInfo(props.release)}
      {renderMediaPlayer([...props.release.side1, ...props.release.side2])}
    </div>
  );
}

function renderReleaseInfo(release: Release): JSX.Element {
  const sections = [release.side1, release.side2];

  return (
    <div className='release-info'>
      {sections.map((tracks, index) => {
        return (
          <section key={index} className='tracks-section'>
            <div className='section-name'>
              Side {index + 1}
            </div>
            {tracks.map(track =>
              <div className='track-info'>
                {track.name} - {track.artist}
              </div>
            )}
          </section>
        );
      })}
    </div>
  );
}

function renderMediaPlayer(tracks: Track[]): JSX.Element {
  const playlist = tracks.map(track => {
    return { title: track.name, url: track.url }
  });

  return (
    <MediaPlayer
      playlist={playlist}
    />
  );
}

