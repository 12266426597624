import FooterComponent from '../components/footer';
import ReleaseComponent from '../components/release';
import Release from '../models/release';

interface Props {
  releases: Release[]
}

export default function ReleasesPage(props: Props): JSX.Element {
  return (
    <div className='releases'>
      {props.releases.map(
        release => <ReleaseComponent key={release.name} release={release} />)
      }
      <FooterComponent />
    </div>
  );
}