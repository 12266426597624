import logo from '../data/alt-logo.png';

export default function FooterComponent(): JSX.Element {
  return (
    <div className='site-footer'>
      <img 
        className='site-footer-logo' 
        src={logo} 
      />
    </div>
  );
}