import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import HomePage from './pages/home';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom';
import releaseList from './data/releases.json';
import ReleasesPage from './pages/releases';
import NewMusicPage from './pages/newmusic';
import ContactPage from './pages/contact';

ReactDOM.render(
  <React.StrictMode>
    <PageRouter />
  </React.StrictMode>,
  document.getElementById('root')
);

function PageRouter(): JSX.Element {
  return (
    <Router>
      <div className='site'>
        <nav className='site-nav'>
          <ul className='site-nav-links'>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/releases">Releases</Link>
            </li>
            <li>
              <Link to="/newmusic">New Music</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>
        </nav>

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/releases" element={<ReleasesPage releases={releaseList.releases} />} />
          <Route path="/newmusic" element={<NewMusicPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
      </div>
    </Router>
  );
}